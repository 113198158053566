import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class AutoInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Auto Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Driving a car is an essential part of most Baton Rouge resident's lives. From your daily commute to road trips, it is crucial to know what you are and aren’t covered for. Contact us for a insurance quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} imgStyle={{objectPosition: 'top'}} alt="Two cars at stop sign" />
        <Callout
          title="Auto Insurance"
          content="Driving a car is an essential part of most Baton Rouge resident's lives. From your daily commute to road trips, it is crucial to know what you are and aren’t covered for. Additionally, it is important to know you are getting the best rate and not spending money unnecessarily. This is why getting car insurance through an independent insurance agent based in Louisiana is important."
        >
          <div className="servicesVideoWrapper"><div className="videoWrapper"><iframe className="iframe services-video" width="560" height="315" src="https://www.youtube.com/embed/0ZwdDlvKdBo?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="Business Interruption Insurance: What You Need to Know" /></div></div>
        </Callout>
        <div className="site-body page-content">
          <h2>What Personal Auto Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Bodily Injury.</strong> Covers you for bodily injury or death from an accident for which you’re at fault and often covers associated legal defense expenses.</li>
          <li><strong>Property Damage.</strong> Protects you if your vehicle accidentally damages another person or business’s property.</li>
          <li><strong>Medical Payments.</strong> Whether you are at fault or not, this usually pays medical expenses of the driver and passengers in the vehicle that are in an accident.</li>
          <li><strong>Uninsured Motorists.</strong> This pays for injuries and sometimes certain property damage when involved in an accident with a driver who is uninsured. This will also usually cover hit-and-run accidents.</li>
          <li><strong>Comprehensive.</strong> This covers vehicle damage resulting from flood, fire, vandalism, theft and other covered perils.</li>
          <li><strong>Collision.</strong> Pays for the damage to a vehicle when it is hit or hits another object.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="New Jersey, New York and Louisiana are the most expensive states for Automobile Insurance. Conversely, Idaho, Iowa and South Dakota are the least expensive states."
            source="Insurance Information Institute (I.I.I.)"
          />
          <h2>What Personal Auto Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Personal Belongings.</strong> Comprehensive insurance will cover the damages to your car if it’s stolen or vandalized, but not any items inside the car. Normally this will be covered under your homeowners or renters insurance policy.</li>
          <li><strong>Individuals not on the policy that live with you.</strong> Generally car insurance will only cover individuals on the policy and those who don’t live with you that have permission to use the car.</li>
          <li><strong>Car Loan for Totaled Vehicle.</strong> Collision and Comprehensive insurance will cover your car’s market value minus your deductible. So if your car is worth less than you owe, you will need to make up the difference. Gap Insurance can be used to mitigate this risk.</li>
          <li><strong>Custom Parts and Equipment.</strong> If your car is damaged or stolen, car insurance will not cover the cost of custom add-ons. However, many policies offer an optional policy to cover this.</li>
          <li><strong>Business Use.</strong> If you use your car for business purposes such as Uber or pizza delivery, you will need to purchase a commercial auto policy since these are excluded under most personal policies.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="an Auto Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default AutoInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "auto-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-20.jpg" }) {
      ...mainBg
    }
  }
`;
